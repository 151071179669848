import { useTranslation } from "react-i18next";
import { H2 } from "../layout/Headings";

interface ToolsProps {
    managementTool?: string;
    respositoryHosting?: string;
    versionControlSystem?: string;
    toolImg?: string;
    ui?: string;
}

const Tools = ({managementTool, respositoryHosting, versionControlSystem, ui, toolImg}: ToolsProps) => {
    const {t} = useTranslation();

    return <section className="border border-gray-200 dark:border-gray-600 text-gray-600 dark:text-gray-400 rounded-lg items-center  text-base md:text-lg p-4 mt-4">

        <div className="grid md:grid-cols-3 gird-cols-2">

            <div className="col-span-2 text-left ">

                <H2 className="mb-2.5">{t('global.tools')}</H2>

                <div className="grid grid-cols-2 ">
                    {managementTool && <>
                        <div className="font-normal">{t('global.project_management_tool')}:</div>
                        <div className="ml-2 font-semibold mb-2">{managementTool}</div>
                    </>
                    }
                    {respositoryHosting && <>
                        <div className="font-normal">{t('global.repository_hosting')}:</div>
                        <div className="ml-2 font-semibold mb-2">{respositoryHosting}</div>
                    </>}
                    {versionControlSystem && <>
                        <div className="font-normal mb-2">{t('global.version_control_system')}:</div>
                        <div className="ml-2 font-semibold">{versionControlSystem}</div>
                    </>}

                    {ui && <>
                        <div className="font-normal">{t('global.design_tool')}:</div>
                        <div className="ml-2 font-semibold">{ui}</div>
                    </>}
                </div>


            </div>

            <div className="relative col-span-1 hidden md:block">
                <img src={`/${toolImg}`} className="absolute -top-6 left-2 h-52 w-52 opacity-80" alt="Tool illustration"></img>
            </div>

        </div>
    </section>
}

export default Tools;