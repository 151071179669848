export enum RoutePath {
    CONTACT = 'hire-me',
    ABOUT_ME = 'about-me',
    EDUCATION = 'education',
    EXPERIENCE = 'experience',
    MY_PATH = '',
    VINTAGE_CARDS = 'vintage-cards',
    BPMS_PAGE = 'bpms',
    PORTFOLIO = 'my-portfolio'
}


export const HEADER_HEIGHT = 60;