import { useRef, useState } from "react";
import ExperienceCard from "./ExperienceCard";
import TimeLine from "./TimeLine";
import ExperienceEmptyState from "./ExperienceEmptyState";
import useIsMobile from "../hooks/useIsMobile";
import { experienceSummaryData } from "../data/experienceSummary";
import useIsTablet from "../hooks/useIsTablet";
import SectionTitle from "./layout/SectionTitle";
import { getSectionNameById } from "../functions/navigation-utils";
import { SectionId } from "../enums/SectionId";


const ExperienceTimeLine = () => {

    const [isExperienceCardVisible, setIsExperienceCardVisible] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const timeLineRef = useRef<HTMLDivElement | null>(null);

    const isMobile = useIsMobile();
    const isTablet = useIsTablet();

    const handleClickTimeLineItem = (index: number | null) => {
        setActiveIndex(index);
        if (!isMobile) {

            setIsExperienceCardVisible(true);
        }
    }

    return <>
        <SectionTitle title={getSectionNameById(SectionId.PROFESSIONAL_EXPERIENCE)} />
        <div className="w-full overflow-hidden scrollbar-hidden">

            <div className='block md:grid md:grid-cols-2 sm:gap-4 justify-center' >
                <div ref={timeLineRef} className="md:flex justify-start md:justify-center w-full md:min-w-40 ">

                    <TimeLine handleClick={handleClickTimeLineItem} activeIndex={activeIndex} />
                </div>
                <div>
                    {!(isMobile || isTablet) && isExperienceCardVisible && activeIndex !== null && <ExperienceCard activeIndex={activeIndex} {...experienceSummaryData[activeIndex]} />}
                    {!(isMobile || isTablet) && activeIndex === null  && <ExperienceEmptyState />}

                </div >
            </div >
        </div >
    </>
};

export default ExperienceTimeLine;