export const experienceSummaryData = [
    {
        image: 'imgs/fourveneus/fourvenues_logo.jpeg',
        company: 'Fourvenues',
        period: 'fourvenues.working_period',
        location: 'global.location.valencia',
        role: 'fourvenues.role',
        experienceTasks: [
            'fourvenues.experience_tasks.line_1',
            'fourvenues.experience_tasks.line_2',
            'fourvenues.experience_tasks.line_3',
            'fourvenues.experience_tasks.line_4',
            'fourvenues.experience_tasks.line_5',
            'fourvenues.experience_tasks.line_6',
            'fourvenues.experience_tasks.line_7',
            'fourvenues.experience_tasks.line_8',
            'fourvenues.experience_tasks.line_9',
            'fourvenues.experience_tasks.line_10',
            'fourvenues.experience_tasks.line_11',
            'fourvenues.experience_tasks.line_12',
            'fourvenues.experience_tasks.line_13',
            'fourvenues.experience_tasks.line_14',
        ]
    },
    {
        image: 'imgs/workday/workday_logo.png',
        company: 'Workday',
        period: 'workday.working_period',
        location: 'global.location.dublin',
        role: 'workday.role',
        companyDescription: 'Por diseño, Workday se ideó para ayudar a las empresas a adaptarse al siempre cambiante panorama normativo. Tanto si opera en 10 países como si lo hace en 100, le ayudamos a trabajar a escala local y a gestionar el negocio a nivel global. Administre y ajuste fácilmente planes, paquetes y reglas de elegibilidad.',
        experienceTasks: [
            'workday.experience_tasks.line_1',
            'workday.experience_tasks.line_2',
            'workday.experience_tasks.line_3',
            'workday.experience_tasks.line_4',
            'workday.experience_tasks.line_5',
            'workday.experience_tasks.line_6',
            'workday.experience_tasks.line_7',
            'workday.experience_tasks.line_8',
            'workday.experience_tasks.line_9',
            'workday.experience_tasks.line_10',
            'workday.experience_tasks.line_11',
            'workday.experience_tasks.line_12',

        ]
    },
    {
        image: 'imgs/switch/switch_logo.png',
        company: 'Switch Software Solutions',
        period: 'switch.working_period',
        location: 'global.location.montevideo',
        role: 'switch.role',
        companyDescription: '',
        experienceTasks: [
            'switch.experience_tasks.line_1',
            'switch.experience_tasks.line_2',
            'switch.experience_tasks.line_3',
            'switch.experience_tasks.line_4',
            'switch.experience_tasks.line_5',
            'switch.experience_tasks.line_6',
            'switch.experience_tasks.line_7',
            'switch.experience_tasks.line_8',
            'switch.experience_tasks.line_9',
            'switch.experience_tasks.line_10',
            'switch.experience_tasks.line_11',
            'switch.experience_tasks.line_12'

        ]
    },
    {
        image: 'imgs/atos/atos_logo.jpg',
        company: 'Atos',
        period: 'atos.working_period',
        location: 'global.location.montevideo',
        role: 'atos.role',
        companyDescription: '',
        experienceTasks: [
            'atos.experience_tasks.line_1',
            'atos.experience_tasks.line_2',
            'atos.experience_tasks.line_3',
            'atos.experience_tasks.line_4',
            'atos.experience_tasks.line_5',
            'atos.experience_tasks.line_6'
        ]
    },

    {
        image: 'imgs/genexus/genexus_consulting_logo.png',
        company: 'Genexus Consulting',
        period: 'genexus.working_period',
        location: 'global.location.montevideo',
        role: 'genexus.role',
        companyDescription: '',
        experienceTasks: [
            'genexus.experience_tasks.line_1',
            'genexus.experience_tasks.line_2',
            'genexus.experience_tasks.line_3',
            'genexus.experience_tasks.line_4',
            'genexus.experience_tasks.line_5',
            'genexus.experience_tasks.line_6',
            'genexus.experience_tasks.line_7',
            'genexus.experience_tasks.line_8',

        ]
    }
];