
import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

interface WrapperProps {
    children: ReactNode;
  }

const SectionWrapper = ({ children }: WrapperProps) => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-10 md:px-8 md:py-16 lg:py-20">
      {children}
    </div>
  );
};

SectionWrapper.propTypes = {
    children: PropTypes.node,
};

export default SectionWrapper;
