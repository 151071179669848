import { H2} from "../layout/Headings";
import { Trans, useTranslation } from "react-i18next";
import { bpmsData } from "../../data/personalProjectsData";
import { SectionId } from "../../enums/SectionId";
import GoBackPageStructure from "../layout/GoBackPageStructure";
import P from "../layout/P";


const BPMSPage = () => {

    const { t } = useTranslation();

    return <GoBackPageStructure 
                title={t('personal_projects.bpms.name')}
                goBackTo={SectionId.PERSONAL_PROJECTS}>
        
                    <section className="mb-10 text-gray-600 dark:text-gray-300">
                        <P>
                            {t('personal_projects.bpms.about_the_project')}
                        </P>
                    </section>

                    <section className="mb-10 text-gray-600 dark:text-gray-300">
                        <H2 className="text-2xl font-bold mb-4"> {t('personal_projects.bpms.solution_title')}</H2>
                        <P>
                            {t('personal_projects.bpms.solution_description')}
                        </P>
                    </section>

                    <section className="mb-10 text-gray-600 dark:text-gray-300">
                        <H2 className="text-2xl font-bold mb-4">  {t('personal_projects.bpms.technologies_title')}</H2>
                        <ul className="list-disc list-inside text-base">
                            {bpmsData.technologies.map((item, index) => (
                                <li className="mb-1" key={index}>
                                    {<Trans i18nKey={item} components={{ bold: <b /> }} />}
                                </li>
                            ))}
                        </ul>
                    </section>

                    <section className="mb-10 text-gray-600 dark:text-gray-300">
                        <H2 className="text-2xl font-bold mb-4">{t('personal_projects.my_portfolio.conclusion_title')}</H2>
                        <P>
                           {t('personal_projects.my_portfolio.conclusion_description')}
                        </P>
                    </section>
     
        </GoBackPageStructure>
}

export default BPMSPage;