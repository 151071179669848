import { useTranslation } from "react-i18next";
import { H2 } from "./layout/Headings";

const ExperienceEmptyState = () => {

    const { t } = useTranslation();

    return <div className="flex items-start justify-center h-screen">
        <div>
            <div className="text-gray-500 text-center mb-8">
                <H2>{t('time_line.empty_state_experience_title')}</H2>
                <span className="inline-block mt-2">{t('time_line.empty_state_experience_subtitle')}</span>
            </div>
            <div className="flex w-full justify-center">

                <img
                    src="imgs/illustrations/journey.svg"
                    alt="Journey"
                    className=" w-2/3 rounded-lg mb-6 opacity-70 dark:brightness-75"
                />
                
            </div>
        </div>

    </div>

}

export default ExperienceEmptyState;