import { useTranslation } from "react-i18next";
import Wrapper from "../layout/Wrapper";
import { SectionId } from "../../enums/SectionId";
import Button, { ButtonType } from "../layout/Button";
import { useRef } from "react";
import { useSectionContext } from "../SectionProvider";
import { useNavigate } from "react-router-dom";


const NotFoundPage = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { updateSectionId } = useSectionContext();

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleGoHome = (HOME: SectionId) => {

    navigate('/');

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      updateSectionId(SectionId.HOME);

    }, 100);
  }


  return (

    <Wrapper>
      <div className="mx-auto p-2 flex  items-center justify-center shadow-inner-custom-soft">
        <div className="p-6 items-center justify-center"
        >
          <div className="flex w-full items-center justify-center p-1 text-3xl font-bold mb-2">
            <div className="w-full text-center">
              <h1 className="text-3xl  font-bold pt-6 pb-4">
                {t('not_found_page.title').toUpperCase()}
              </h1>

              <div className="flex items-center justify-center text-sm md:text-base text-gray-800 font-normal py-1 w-full">
                <p>{t('not_found_page.subtitle')}</p>
              </div>

            </div>
          </div>

          <div className="flex items-center justify-center text-center mt-12">

            <img
              src="/imgs/illustrations/error_404.svg"
              alt="404 Not Found"
              className="w-full object-contain mb-2  p-2"
            />


          </div>
          <div className="flex items-center justify-center mt-4">
              <Button
                type={ButtonType.PRIMARY_ROUNDED}
                handleClick={() => handleGoHome(SectionId.HOME)}
              >
                {t('global.action.go_home')}
              </Button>
            </div>
        </div>

      </div>
    </Wrapper>

  )
};

export default NotFoundPage;
