interface HeadingProps {
    children: React.ReactNode;
    className?: string;
  }
  

  export const H1 = ({ children, className }: HeadingProps) => {
    return <h1 className={`text-3xl font-bold text-gray-600 dark:text-gray-200 ${className}`}>{children}</h1>;
  };
  

  export const H2 = ({ children, className }: HeadingProps) => {
    return <h2 className={`text-lg font-semibold text-gray-600 dark:text-gray-200 ${className}`}>{children}</h2>;
  };
  

  export const H3 = ({ children, className }: HeadingProps) => {
    return <h3 className={`text-base font-semibold text-gray-600 dark:text-gray-200 ${className}`}>{children}</h3>;
  };