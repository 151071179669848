import { useTranslation } from "react-i18next";
import { SectionId } from "../../enums/SectionId";
import { scrollToSection } from "../../functions/navigation-utils";
import Button, { ButtonType } from "../layout/Button";
import { useSectionContext } from "../SectionProvider";
import { useNavigate } from "react-router-dom";
import TechnicalSkills from "../ExperienceExtended/TechnicalSkills";
import P from "../layout/P";
import { H1, H2 } from "../layout/Headings";
import { motion } from "framer-motion";
import { useRef, useState } from "react";
import { useDarkMode } from "../../hooks/useDarkMode";
import Spinner from "../layout/Spinner";


const technicalSkills = [
    'React', 'Angular', 'Javascript', 'Typescript', 'HTML', 'CSS', 'Tailwind', 'Storybook', 'Emotion', 'Jest', 'Jasmine & Karma', 'NodeJS', 'RESTfull endpoints', 'GraphQL', 'Cypress'
]

const toolSkills = [
    'Jira', 'Confluence', 'Scrum', 'GitHub', 'GitLab', 'Bitbucket', 'Notion'
]

const AboutMePage = () => {

    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();

    const isDarkMode = useDarkMode();

    const navigate = useNavigate();
    const { updateSectionId } = useSectionContext();
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handleGoToSection = (sectionId: SectionId) => {
        
        navigate('/');

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            updateSectionId(sectionId);
            scrollToSection(sectionId);
        
        }, 100);
    }

    return (<div className="bg-light-yellow dark:bg-gray-800 min-h-screen">

        {isLoading && <div className="flex items-center justify-center w-screen h-full mt-[60px] p-12">
            <Spinner />
        </div>}

        <div className="relative w-full flex items-center justify-center space-x-4">

            {!isLoading && <><div className="absolute inset-0 bg-cover bg-center opacity-40"
                style={{
                    backgroundImage: `url('/imgs/bg/hero-bg-light.webp')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            ></div>

            <div className={`absolute inset-0 ${isDarkMode ? 'brightness-50' : ''}`} style={{
                backgroundImage: `url('/imgs/illustrations/bg-coding.svg')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }} /> 
            
            </>}

            <motion.div
                className="relative w-full flex items-center justify-center"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
            >
                <img src="/imgs/about/karen.png" alt="Me" className="relative md:-bottom-[115px] md:h-[70vh] object-cover brightness-125 z-20"
                    onLoad={() => setIsLoading(false)} />
            </motion.div>

        </div>


        {!isLoading &&
            <div className="max-w-7xl mx-auto px-4 pb-4 md:px-8 md:pb-8 lg:px-8 lg:pb-8">


                <div className="max-w-4xl mx-auto p-4 md:p-8 bg-white dark:bg-gray-800  shadow-md rounded-lg text-gray-700">

                    <div className="flex items-center mb-1">
                        <Button
                            icon="fas fa-arrow-left"
                            type={ButtonType.PRIMARY}
                            handleClick={() => handleGoToSection(SectionId.HOME)}
                        >
                            {t('global.action.go_back')}
                        </Button>
                    </div>

                    <div className="mb-8  flex justify-center">
                        <H1> {t('about_me.title')}</H1>
                    </div>
                    <div className="relative border-gray-100 border dark:border-gray-600 p-4 rounded-md">
                        <P>{t('about_me.description')}</P>
                    </div>

                    <section className="mt-10">
                        <H2 className="mb-2">{t('about_me.journey_title')}</H2>
                        <P>{t('about_me.journey_description')}</P>
                        <div className="mt-2"></div>

                        <Button
                            type={ButtonType.PRIMARY}
                            handleClick={() => handleGoToSection(SectionId.PROFESSIONAL_EXPERIENCE)}
                        >
                            {t('about_me.action_navigation_journey')}
                        </Button>
                    </section>

                    <section className="mt-10">
                        <H2 className="mb-2">Skills</H2>

                        <TechnicalSkills technicalSkills={technicalSkills} />
                        <div className="mt-2"></div>
                        <TechnicalSkills technicalSkills={toolSkills} />

                    </section>

                </div>

            </div>}
    </div>)
}

export default AboutMePage;