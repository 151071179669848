export const certificates = [
    {
        name: 'education.certificate.ux.name',
        issuingEntity: 'Google |  Coursera',
        period: 'education.certificate.ux.period',
        link: 'https://www.coursera.org/account/accomplishments/specialization/certificate/5MKCSHWMGU3R'
    },
    {
        name: 'education.certificate.grafic_design.name',
        issuingEntity: 'Idecrea',
        location: 'global.location.valencia',
        period: 'education.certificate.grafic_design.period'
    },
    {
        name: 'education.certificate.marketing.name',
        issuingEntity: 'Google',
        period: 'education.certificate.marketing.period'
    },
    {
        name: 'education.certificate.english_business.name',
        issuingEntity: 'education.certificate.english_business.issuing_entity',
        location: 'global.location.dublin',
        period: 'education.certificate.english_business.period'
    },
    {
        name: 'education.certificate.tie.name',
        issuingEntity: 'IELT',
        location: 'global.location.dublin',
        period: 'education.certificate.tie.period'
    },
    {
        name: 'education.certificate.genexusEvo1.name',
        issuingEntity: 'Genexus',
        location: 'global.location.montevideo',
        period: 'education.certificate.genexusEvo1.period'
    },
    {
        name: 'education.certificate.genexus.name',
        issuingEntity: 'Genexus',
        location: 'global.location.montevideo',
        period: 'education.certificate.genexus.period'
    },
    {
        name: 'First Certificate in English',
        issuingEntity: 'education.certificate.first.issuing_entity',
        location: 'global.location.montevideo',
        period: 'education.certificate.first.period'
    }

];


export const univeristyData = [
    {
        title: 'education.university.engineering.title',
        issuingEntity: 'education.university.engineering.issuing_entity',
        location: 'global.location.montevideo',
        period: '2007 - 2015',
    },
    {
        title: 'education.university.analyst.title',
        issuingEntity: 'education.university.analyst.issuing_entity',
        location: 'global.location.montevideo',
        period: '2007 - 2015 ',
        observation: 'education.university.analyst.observation'
    }
]



export const languagesData = [
    {
        name: 'education.language.spanish',
        level: 'education.language.spanish_level'
    },
    {
        name: 'education.language.english',
        level: 'education.language.english_level'
    }
]