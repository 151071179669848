import { useTranslation } from "react-i18next";
import { H1 } from "../layout/Headings";
import TextIconLink from "../layout/TextIconLink";
import P from "../layout/P";

interface CompayInformationProps {
    company: string;
    image: string;
    companyDescription: string;
    link: string;
}

const CompayInformation = ({ company, image, companyDescription, link }: CompayInformationProps) => {
    const { t } = useTranslation();

    return <section className="block md:flex justify-start w-full items-center border border-gray-200 dark:border-gray-700 rounded-lg p-4 md:divide-x md:divide-y-0 divide-y divide-gray-200 dark:divide-gray-600">

        <div className="md:max-w-52 w-full items-center justify-center mr-4">
            <div className="flex items-center justify-center w-full">
                <img className="rounded-full border border-gray-200 dark:border-gray-600 shadow-lg h-12 w-12" src={`/${image}`} alt="logo" />
            </div>
            <div className="flex items-end justify-center text-center w-full my-2">
                <H1>{company}</H1>
                <div className="mb-2"><TextIconLink link={link} /></div>
            </div>
        </div>

        <div className="p-2 md:pl-4">
            <P> {t(companyDescription)}</P> 
        </div>
    </section>
}

export default CompayInformation;