import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, Variants } from 'framer-motion';
import Button, { ButtonType } from './Button';
import { useTranslation } from 'react-i18next';
import { headerSections } from '../../data/sectionsData';
import { useSectionContext } from '../SectionProvider';
import { scrollToSection } from '../../functions/navigation-utils';
import { useDarkMode } from '../../hooks/useDarkMode';

const menu = {
    closed: {
        scale: 0,
        transition: {
            delay: 0.15,
        },
    },
    open: {
        scale: 1,
        transition: {
            type: "spring",
            duration: 0,
            delayChildren: 0.2,
            staggerChildren: 0.1,
        },
    },
} satisfies Variants;

const itemVariants: Variants = {
    closed: { x: -16, opacity: 0 },
    open: { x: 0, opacity: 1 },
};

const HamburgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const isDarkMode  = useDarkMode();

    const { t } = useTranslation();

    const location = useLocation();
    const isRoot = location.pathname === '/';

    const handleToggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const navigate = useNavigate();
    const { selectedSectionId, updateSectionId } = useSectionContext();

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    
    const handleSectionClick = (sectionId: string) => {
        console.log('Section', sectionId)
        if (!isRoot) {
          navigate('/');

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                scrollToSection(sectionId);
            }, 100);
            return;
        }

        handleClose();
    
        updateSectionId(sectionId);
        const section = document.getElementById(sectionId);
        if (section) {
          scrollToSection(sectionId)
        }
      };

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return (
        <div>
            <div className="text-2xl">
                <Button
                    icon={isOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-bars'}
                    type={ButtonType.PRIMARY}
                    handleClick={handleToggleMenu}
                />
            </div>

            {isOpen && (
                <>
                    {/* Menu Overlay */}
                    <div className={`fixed top-52 inset-0 bg-black bg-opacity-50 -z-50`} onClick={handleClose}></div>

                    {/* Menu Container */}
                    <motion.div
                        initial="closed"
                        animate={isOpen ? "open" : "closed"}
                        variants={menu}
                        className={`absolute top-16 left-0 w-full bg-light-green flex flex-col items-center pb-4 shadow-md shadow-inner-gray p-2`}
                        style={isDarkMode
                            ? {
                                backgroundImage: `url('imgs/bg/menu-bg.png')`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                            }
                            : { }}
                    >
                        <nav className="w-full">
                            <ul className=" w-full">
                                {headerSections.map(({ id, name }, index) => (
                                     (index > 0) && <motion.div
                                        key={'div-' + id}
                                        variants={itemVariants}
                                        className={`relative p-4 w-full border-b border-gray-200 dark:border-gray-400 hover:text-blue-100 dark:hover:text-blue-50 ${id === selectedSectionId ? 'text-happy-blue bg-opacity-40 bg-orange-50 p-1' : ' text-happy-blue bg-opacity-20 p-1'}`}
    
                                    >
                                     <li key={id}>
                                            <button
                                                onClick={() => handleSectionClick(id)}
                                                className={`w-full text-sm text-left hover:text-blue-500 ${selectedSectionId === id ? 'text-happy-blue dark:text-gray-50' : 'text-gray-500 dark:text-gray-200'}`}
                                            >
                                                {t(name).toUpperCase()}
                                            </button>
                                        </li>

                                    </motion.div>
                                ))}
                            </ul>
                        </nav>

                    </motion.div>
                </>
            )
            }
        </div >
    );
};

export default HamburgerMenu;
