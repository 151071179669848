interface ColorPaletteProps {
    color: string;
}
const ColorPalette = ({ color }: ColorPaletteProps) => {
    return <div className="mb-2 md:mb-0 mr-0 md:mr-4">
        <div className={`rounded-lg w-8 h-8  md:w-12 md:h-12 bg-[${color}]`}></div>
        <div className="italic text-sm md:mt-2 md:ml-0 text-gray-700">{color}</div>
    </div>
};

export default ColorPalette;