import { useTranslation } from "react-i18next";
import { languagesData } from "./data/educationData";


const Language = () => {
    const {t} = useTranslation();
    
    return <>
        {languagesData.map((item, index) => (
            <div key={index} className="flex justify-between items-center mb-4">
                <p className="font-semibold">
                    <span className="mr-2 text-light-pastel-blue text-sm">
                        <i className="fas fa-language"></i>
                    </span>
                    <span className="text-gray-600 dark:text-gray-200">{t(item.name)}</span></p>
                <p className="text-gray-500 dark:text-gray-300">{t(item.level)}</p>
            </div>
        ))}
    </>



}

export default Language;