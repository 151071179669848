import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootLayout from './components/layout/RootLayout';
import NotFoundPage from './components/pages/NotFountPage';
import ProfessionalExperienceExtendedPage from './components/pages/ProfessionalExperienceExtendedPage';
import { RoutePath } from './enums/RoutePath.enum';
import VintageCardsPage from './components/pages/VintageCardsPage';
import ResumePage from './components/pages/ResumePage';
import { SectionProvider } from './components/SectionProvider';
import BPMSPage from './components/pages/BPMSPage';
import PortfolioPage from './components/pages/PorfolioPage';
import AboutMePage from './components/pages/AboutMePage';

const router = createBrowserRouter([
  {
    path: '',
    element: <RootLayout />,
    children: [
      { index: true, element: <ResumePage /> },
      { path: `/${RoutePath.VINTAGE_CARDS}`, element: <VintageCardsPage /> },
      { path: `/${RoutePath.BPMS_PAGE}`, element: <BPMSPage /> },
      { path: `/${RoutePath.PORTFOLIO}`, element: <PortfolioPage /> },
      { path: `/${RoutePath.EXPERIENCE}/:companyName`, element: <ProfessionalExperienceExtendedPage /> },
      { path: `/${RoutePath.ABOUT_ME}`, element: <AboutMePage /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
]);

function App() {

  return (<div className='min-h-screen bg-gray-100 dark:bg-gray-900 overflow-x-hidden'>
    <SectionProvider>
      <RouterProvider router={router} />
    </SectionProvider>

  </div>);
}

export default App;
