import { useParams } from "react-router-dom";
import ExperienceExtended from "../ExperienceExtended/ExperienceExtended";
import { experienceExtendedData } from "../../data/experienceExtended";
import { ExperienceDataIndex } from "../../enums/ExperienceDataIndex.enum";
import { HEADER_HEIGHT } from "../../enums/RoutePath.enum";

const ProfessionalExperienceExtendedPage = () => {
    const { companyName } = useParams();

    const companyKey = companyName?.toUpperCase();
    let comapnyIndex: number;

    if (companyKey && companyKey in ExperienceDataIndex) {
        comapnyIndex = ExperienceDataIndex[companyKey as keyof typeof ExperienceDataIndex];
    } else {
        console.log('Invalid company key');
        return null
    }


    return <div className={`mt-[${HEADER_HEIGHT}px]`}>
        <ExperienceExtended {...experienceExtendedData[comapnyIndex]} />
    </div>

}

export default ProfessionalExperienceExtendedPage;