import React, { createContext, useContext, useState } from 'react';

const SectionContext = createContext<{
  selectedSectionId: string | null;
  updateSectionId: (section: string) => void;
}>({
  selectedSectionId: null,
  updateSectionId: () => {},
});

export const SectionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedSectionId, setSelectedSectionId] = useState<string | null>(null);

  const updateSectionId = (id: string) => {

    if (selectedSectionId !== id) {
      setSelectedSectionId(id);
    }
  };

  return (
    <SectionContext.Provider value={{ selectedSectionId, updateSectionId }}>
      {children}
    </SectionContext.Provider>
  );
};

export const useSectionContext = () => useContext(SectionContext);

