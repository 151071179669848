import { Outlet } from "react-router-dom";
import Header from "./Header";
import ScrollToTop from "../ScrollToTop";
import { HEADER_HEIGHT } from "../../enums/RoutePath.enum";


const RootLayout = () => {

    return (
        <>
            <Header />
            <main style={{marginTop: `${HEADER_HEIGHT}px` }}>
                <ScrollToTop />
                <Outlet />
            </main>
        </>
    )
}

export default RootLayout;