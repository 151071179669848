import { experienceSummaryData } from "../data/experienceSummary";
import TimeLineItem from "./TimeLineItem";


interface TimeLineProps {
    handleClick: (index: number | null) => void;
    activeIndex: number | null
}

const TimeLine = ({ handleClick, activeIndex }: TimeLineProps) => {
    return <div className="overflow-y-auto scrollbar-hidden overflow-x-hidden px-4 w-full " >
            <div className="">
                <ol className="relative border-s border-gray-300 dark:border-gray-500 ">
                    {experienceSummaryData.map((item, index) => (
                        <TimeLineItem key={index} {...item} index={index} handleClick={handleClick} activeIndex={activeIndex} />
                    ))}
                </ol>
            </div>
        </div>
}

export default TimeLine;