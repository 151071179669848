import { useTranslation } from "react-i18next";
import useIsMobile from "../../hooks/useIsMobile";
import useIsTablet from "../../hooks/useIsTablet";

interface SectionTitleProps {
    title: string;
}

const SectionTitle = ({ title }: SectionTitleProps) => {

    const { t } = useTranslation();


    const isMobile = useIsMobile();
    const isTablet = useIsTablet();

    return <> 
        {(isMobile || isTablet) ? <div className="w-full flex items-center justify-center mb-8 text-3xl text-gray-600 dark:text-gray-200">
            <h2 className="font-semibold text-2xl border-b-4 border-light-pastel-blue">{t(title)}</h2>
            </div>
            :
            null
        }
     </>

}

export default SectionTitle;