import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { H2, H3 } from "../layout/Headings";
import P from "../layout/P";

interface ProjectsItem {
    title: string;
    description: string;
    image: string;
}

interface ProjectsProps {
    projects: ProjectsItem[]
}

const Projects = ({ projects }: ProjectsProps) => {

    const { t } = useTranslation();
    const projectsLenght = projects.length;

    return <section className="border border-gray-200 dark:border-gray-600 text-gray-600 dark:text-gray-400 rounded-lg p-4">
        <div className="flex justify-start font-semibold mb-2">
            <H2><>{t(projects.length > 1 ? 'global.projects' : 'global.project')}</></H2>
        </div>

        {
            projects.map((project: ProjectsItem, index: number) => (
                <Fragment key={index}>
                    <div className="block md:flex md:items-center md:divide-x divide-gray-300 dark:divide-gray-600">

                        <div className="flex justify-center items-center w-full md:w-auto md:block md:text-left md:min-w-40 md:mr-4 mb-2 md:mb-0 h-full">
                            <img
                                src={`/${project.image}`}
                                className="object-cover h-40 w-40 border border-gray-300 rounded-lg opacity-90"
                                alt="Project"
                            />
                        </div>
                        <div className="text-left md:pl-4">
                            <H3 className="mt-2 md:mt-0 flex justify-center md:justify-start">{project.title}</H3>
                            <div  className="flex-1 mt-2">
                                <P>{t(project.description)}</P>
                                
                            </div>
                        </div>
                    </div>

                    {index < projectsLenght - 1 && (
                        <div className="border-t  border-gray-200 dark:border-gray-600 m-4" />
                    )}
                </Fragment>
            ))
        }
    </section>
}

export default Projects;