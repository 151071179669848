interface TechnicalSkillsProps {
    technicalSkills: string[]
}

const TechnicalSkills = ( { technicalSkills } : TechnicalSkillsProps) => {
    return <section className="flex flex-wrap text-left text-xs border border-gray-200 dark:border-gray-600 rounded-lg p-2 gap-2">
        {
            technicalSkills.map((item: string, index: number) => (
                <div className="font-normal whitespace-nowrap bg-gray-50 dark:bg-gray-900 text-gray-700 dark:text-gray-400 rounded-full px-2 py-1" key={`skill-${index}`}>
                    {item}
                </div>
            ))
        }
    </section>
}

export default TechnicalSkills;