import { useTranslation } from "react-i18next";
import { certificates } from "../data/educationData";
import Language from "../Language";
import Certification from "./layout/Certification";
import { H3 } from "./layout/Headings";
import SectionTitle from "./layout/SectionTitle";
import University from "./University";
import { getSectionNameById } from "../functions/navigation-utils";
import { SectionId } from "../enums/SectionId";


const EducationSection = () => {
    const { t } = useTranslation();

    return <>
        <SectionTitle title={getSectionNameById(SectionId.EDUCATION)} />
        <div className="md:flex mt-2">

            <University />

            <div className="md:ml-auto bg-white dark:bg-gray-700 text-gray-900 items-baseline text-sm md:text-base border border-gray-200 dark:border-gray-600 shadow-md rounded-lg p-4 w-full md:w-1/4 text-left ml-0 md:ml-6 mt-6 md:mt-0">

                <div className="mb-4 text-lg font-bold flex items-center">
                    <H3>{t('education.subtitle.language')}</H3>
                </div>

                <div className="mt-6">
                    <Language />
                </div>

            </div>

        </div>


        <div className="mt-10">
            <H3>{t('education.subtitle.certificate')}</H3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 mt-4">
                {
                    certificates.map((certificate, index) => (
                        <Certification key={index} {...certificate} />
                    ))
                }

            </div>



        </div>
    </>;
}

export default EducationSection;