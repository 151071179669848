import { useTranslation } from "react-i18next";
import { univeristyData } from "../data/educationData";

const University = () => {

    const { t } = useTranslation();

    return <div className="p-4 border border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700 shadow-md rounded-lg w-full md:w-2/3 mr-4">

        <div className="flex w-full">
            <div className=" text-base md:text-lg text-gray-600 dark:text-gray-300">
                {univeristyData.map(({ title, issuingEntity, location, period, observation }, index) => (
                    <div key={title} className="flex mb-1 items-baseline">
                        <div className="mr-4 flex h-full items-center justify-center text-light-pastel-blue text-sm">
                            <i className="fa-solid fa-graduation-cap"></i>
                        </div>
                        <div className={index === 1 ? 'mt-4': ''}>
                            <div className="font-semibold mb-2 text-gray-600 dark:text-gray-100">
                                {t(title)}
                            </div>
                            <div className="flex mb-1 items-baseline">
                                {t(issuingEntity)}
                            </div>
                            <div className="flex mb-1 items-baseline">
                                {t(location)}
                            </div>
                            <div className="italic">
                                {t(period)}
                                {observation && <span className="text-sm md:text-base text-gray-500 dark:text-gray-400">| {t(observation)}</span>}
                            </div>
                        </div>
                    </div>
                ))}

            </div>
            <div className="hidden md:block flex items-center justify-center ml-auto w-1/3">
                <img className="rounded-lg object-contain h-auto opacity-80 dark:brightness-75" src="/imgs/illustrations/graduation.svg" alt="Graduation" />
            </div>
        </div>
    </div>
}

export default University;