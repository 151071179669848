import { useTranslation } from "react-i18next";
import ButtonLink, { ButonLinkType } from "./layout/ButtonLink";
import { H2 } from "./layout/Headings";

interface PersonalProjectCardProps {
    id: string;
    projectName: string;
    period: string;
    description: string;
    image: string;
    imageDecoration: string;
}

const PersonalProjectCard = ({ id, projectName, period, description, image, imageDecoration }: PersonalProjectCardProps) => {

    const { t } = useTranslation();

    return <div className="bg-white dark:bg-gray-700 rounded-lg shadow-md border border-gray-200 dark:border-gray-600 flex flex-col justify-between h-full relative">
        <div className="px-4 pt-4 flex-grow z-0 relative">
            <H2>{t(projectName)}</H2>
        </div>

        <div className="h-52 max-w-72">
            <div className="text-start text-justify text-base md:text-lg text-gray-900 dark:text-gray-300 mt-2 ml-4 min-h-12">
                {t(description)}
            </div>

        </div>

        <div className="absolute inset-0 z-10 max-h-[130px] top-52 flex items-end">
      
            <div className="flex relative z-0 mb-4">
                <div className="ml-4">
                    <ButtonLink to={`/${id}`} type={ButonLinkType.PRIMARY}>
                        {t('global.action.explore_more')}
                    </ButtonLink>
                </div>
            </div>
            <img
                src={imageDecoration}
                alt="Project"
                className="object-cover ml-auto w-3/5 rounded-sm"
            />
        </div>
    </div>
}

export default PersonalProjectCard