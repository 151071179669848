
import ColorPalette from "../layout/ColorPalette";
import { vintageCardData } from "../../data/personalProjectsData";
import { Trans, useTranslation } from "react-i18next";
import { SectionId } from "../../enums/SectionId";
import GoBackPageStructure from "../layout/GoBackPageStructure";
import P from "../layout/P";


const VinageCardsPage = () => {

    const { t } = useTranslation();

    return <GoBackPageStructure
        title="Vintage Cards"
        goBackTo={SectionId.PERSONAL_PROJECTS}>

        <section className="w-full mb-8">
            <P>
                {t('personal_projects.vintage_cards.about_the_project')}
            </P>
        </section>


        <section className="w-full mt-8 mb-8 text-gray-600 dark:text-gray-300">
            <h2 className="text-2xl font-semibold">{t('personal_projects.vintage_cards.motivation_title')}</h2>
            <h3 className="text-xl font-medium mt-8 mb-2">{t('personal_projects.vintage_cards.problem_title')}</h3>
            <P>
                {t('personal_projects.vintage_cards.the_problem')}
            </P>
        </section>


        <section className="w-full mb-8 text-gray-600 dark:text-gray-300">
            <h3 className="text-xl font-medium mb-2"> {t('personal_projects.vintage_cards.relevance_title')}</h3>
            <P>
                {t('personal_projects.vintage_cards.necessity')}
            </P>
        </section>


        <section className="w-full mb-8 text-gray-600 dark:text-gray-300">
            <h3 className="text-xl font-medium mb-2">{t('personal_projects.vintage_cards.solution_title')}</h3>
            <P>
                {t('personal_projects.vintage_cards.solution')}
            </P>
            <div className="lg:flex mt-4">
                <div className="w-full">
                    <ul className="space-y-4">
                        {vintageCardData.features.map((item, index) => (
                            <li key={index}>
                                <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg text-gray-600 dark:text-gray-300">
                                    <span className="font-semibold mr-2">
                                        {t(item.name)}
                                    </span>
                                    <P>
                                        {t(item.description)}
                                    </P>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">

                <div className="relative group overflow-hidden rounded-lg shadow-sm p-2">
                    <img className="object-contain w-full h-48 lg:h-64" alt="card catalog"
                        src={`/${vintageCardData.features[2].image}`} />
                    <div className="flex justify-center items-center mt-2">
                        <span className="text-gray-500 text-sm  italic">{t(vintageCardData.features[2].imageName)}</span>
                    </div>
                </div>

                <div className="relative group overflow-hidden rounded-lg shadow-sm p-2">
                    <img className="object-contain w-full h-48 lg:h-64"alt="card article"
                        src={`/${vintageCardData.features[4].image}`} />
                    <div className="flex justify-center items-center mt-2">
                        <span className="text-gray-500 text-sm italic">{t(vintageCardData.features[4].imageName)}</span>
                    </div>
                </div>
            </div>

        </section>


        <section className="w-full mb-8 text-gray-600 dark:text-gray-300">
            <h3 className="text-xl font-medium mb-2">{t('personal_projects.vintage_cards.technologies_title')}</h3>
            <ul className="list-disc list-inside text-base">
                {
                    vintageCardData.technologies.map((item, index) => (
                        <li className="mb-1" key={index}>
                            {<Trans i18nKey={item} components={{ bold: <b /> }} />}
                        </li>
                    ))}
            </ul>
        </section>


        <section className="w-full mb-8 text-gray-600 dark:text-gray-300">
            <h3 className="text-xl font-medium mb-2">{t('personal_projects.vintage_cards.design_title')}</h3>
            <P>
                {t('personal_projects.vintage_cards.design')}
            </P>
            <div className="border border-gray-200 dark:border-gray-700 rounded-lg p-4 mt-4">
                <h4 className="mb-2 text-base font-medium">{t('personal_projects.vintage_cards.palette_colors')}</h4>
                <div className="lg:flex items-center">
                    <P>
                        {t('personal_projects.vintage_cards.colors_palette')}
                    </P>
                    <div className="grid grid-cols-3 md:flex justify-center mt-4 ml-0 md:ml-8">
                        {vintageCardData.colorsPalette.map(({ hexa }) => (
                            <ColorPalette key={hexa} color={hexa} />
                        ))}
                    </div>
                </div>
            </div>


            <div className="w-full md:flex justify-between border border-gray-200 dark:border-gray-700 rounded-lg p-4 mt-4">
                <div className="w-full">
                    <h3 className="mb-2 text-base font-medium">Logo</h3>
                    <div className="flex items-center">
                        <img className="h-28 w-28 object-contain" src="/imgs/projects/vintage_cards.png" alt="vintage cards logo" />
                        <div className="ml-8 text-justify">
                           <P>{t('personal_projects.vintage_cards.logo')}</P> 
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="w-full mt-8 text-gray-600 dark:text-gray-300">
            <h2 className="text-2xl font-semibold mb-2">{t('personal_projects.vintage_cards.conclusion_title')}</h2>
            <P>
                {t('personal_projects.vintage_cards.conclusion_description')}
            </P>
        </section>

    </GoBackPageStructure>

}

export default VinageCardsPage;

