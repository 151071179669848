import { useNavigate } from "react-router-dom";
import Button, { ButtonType } from "./Button";
import { useSectionContext } from "../SectionProvider";
import { SectionId } from "../../enums/SectionId";
import { scrollToSection } from "../../functions/navigation-utils";
import { H1 } from "./Headings";
import { ReactNode, useRef } from "react";
import Wrapper from "./Wrapper";
import { useTranslation } from "react-i18next";

interface GoBackPageStructureProps {
    title?: string;
    children: ReactNode;
    goBackTo: SectionId
}

const GoBackPageStructure = ({ title, goBackTo, children }: GoBackPageStructureProps) => {

    const {t} = useTranslation();

    const navigate = useNavigate();
    const { updateSectionId } = useSectionContext();

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handleGoBack = () => {

        navigate('/');

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            updateSectionId(goBackTo);
            scrollToSection(goBackTo)
        }, 100);
    }
    
    return <Wrapper>
        <div className="max-w-4xl mx-auto p-4 md:p-8 bg-white dark:bg-gray-800 shadow-md rounded-lg text-gray-700">

            <div className="flex items-center mb-1">
                <Button
                    icon="fas fa-arrow-left"
                    type={ButtonType.PRIMARY}
                    handleClick={handleGoBack}
                > 
                {t('global.action.go_back')} 
                </Button>
            </div>

            {title && <div className="mb-8  flex justify-center">
                <H1> {title}</H1>
            </div>}

            <div>
                {children}
            </div>

            <div className="flex items-center mt-14">
                <Button
                    icon="fas fa-arrow-left"
                    type={ButtonType.PRIMARY}
                    handleClick={handleGoBack}
                > 
                {t('global.action.go_back')} 
                </Button>
            </div>

        </div>
    </Wrapper>

}

export default GoBackPageStructure;