import React from "react";
import { Link } from "react-router-dom";

interface ButtonLinkProps {
  to: string;
  children: React.ReactNode;
  type?: ButonLinkType
}

export enum ButonLinkType {
  PRIMARY= 'primary',
  PRIMARY_ROUNDED= 'primary-rounded',
  SECONDARY_ROUNDED ='gray'
}

const ButtonLink = ({ to, children, type = ButonLinkType.PRIMARY }: ButtonLinkProps) => {

  const getButtonClass = () => {

    let classes = '';


    if (type === ButonLinkType.PRIMARY)
      classes += ' text-happy-blue hover:text-blue-400';

    if (type === ButonLinkType.PRIMARY_ROUNDED)
      classes += ' text-gray-50 bg-happy-blue rounded-lg shadow-md hover:bg-blue-400 px-4 py-2';

    if (type === ButonLinkType.SECONDARY_ROUNDED)
      classes += ' text-gray-50 bg-gray-300 dark:bg-gray-500 rounded-lg shadow-md hover:bg-gray-400 dark:hover:bg-gray-400 px-4 py-2';


    return classes;

  }

  return (
    <Link
      to={to}
      className={`inline-block font-semibold text-sm md:text-base text-center ' ${getButtonClass()}`}
    >
      {children}
    </Link>
  );
};

export default ButtonLink;