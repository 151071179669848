
import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

interface WrapperProps {
    children: ReactNode;
  }

const Wrapper = ({ children }: WrapperProps) => {
  return (
    <div className="max-w-7xl mx-auto p-4 md:p-8 lg:p-8">
      {children}
    </div>
  );
};

Wrapper.propTypes = {
    children: PropTypes.node,
};

export default Wrapper;
