import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const DarkModeToggle = () => {
  const {t} = useTranslation();
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <button
      onClick={() => setIsDarkMode(prev => !prev)}
      className="w-20 flex items-center justify-center py-1 px-2 rounded-full bg-gray-200 hover:bg-gray-300  rounded-full bg-slate-200 dark:bg-gray-800 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-200 transition duration-300"
    >
     
      {!isDarkMode && <span className="text-xs mr-1 text-gray-600">{t('header.mode.light').toUpperCase()}</span>}
      <span className="text-xs text-gray-600 dark:text-gray-300">
        <i className={isDarkMode ? 'fa-solid fa-moon' : 'fa-solid fa-sun'}></i>
      </span>
      {isDarkMode && <span className="text-xs ml-1 text-gray-300">{t('header.mode.dark').toUpperCase()}</span>}

    </button>
  );
};

export default DarkModeToggle;
