import { useTranslation } from "react-i18next";
import { RoutePath } from "../enums/RoutePath.enum";
import ButtonLink, { ButonLinkType } from "./layout/ButtonLink";
import { useDarkMode } from "../hooks/useDarkMode";
import Button, { ButtonType } from "./layout/Button";
import { SectionId } from "../enums/SectionId";
import { scrollToSection } from "../functions/navigation-utils";
import { useSectionContext } from "./SectionProvider";

const HomeSection = () => {
    const { t } = useTranslation();

    const isDarkMode = useDarkMode();

    const {  updateSectionId } = useSectionContext();

    const handleClick = () => {
        updateSectionId(SectionId.PROFESSIONAL_EXPERIENCE);
        const section = document.getElementById(SectionId.PROFESSIONAL_EXPERIENCE);
        if (section) {
          scrollToSection(SectionId.PROFESSIONAL_EXPERIENCE)
        }

    }


    return <><div className="w-full h-screen"
        style={{
            backgroundImage: `url('/imgs/bg/hero-bg-light.webp')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            opacity: '20%'
        }}
    >
    </div>

        <div className="absolute top-[450px] left-1/2 transform -translate-x-1/2 -translate-y-1/2">

            <div className="flex flex-col md:flex-row items-center justify-center">
                
                <div className="text-center md:text-left md:min-w-80 text-4xl md:text-5xl text-gray-600 dark:text-gray-200 mb-10">
                    <p className="mb-4">{t('home.greeting')}</p>
                    <p>{t('home.i_am')} <span className="text-light-pastel-blue"> Karen Bas</span></p>

                    <p className="text-3xl md:text-3xl mt-4 text-light-pastel-blue my-8">{t('home.profession')}</p>

                    <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2">
                        <Button
                            type={ButtonType.PRIMARY_ROUNDED}
                            handleClick={handleClick}
                        >
                             {t('header.menu.experience')}
                            
                        </Button>
                        <ButtonLink
                            to={`/${RoutePath.ABOUT_ME}`}
                            type={ButonLinkType.SECONDARY_ROUNDED}>
                           {t('global.action.about_me')}
                        </ButtonLink>
                    </div>
                </div>

                <img
                    src="/imgs/illustrations/hand-coding.svg"
                    alt="Project"
                    className={`object-cover w-full md:w-[800px] h-auto ${isDarkMode ? 'brightness-50' : ''}`}
                />

            </div>

        </div>
        <p className="relative flex justify-end pr-2 w-full text-xs text-gray-300 dark:text-gray-500 italic">
            <a href="https://storyset.com" target="_blank" rel="noreferrer">{t('home.illustrations')}</a>
        </p>

    </>
}

export default HomeSection;
