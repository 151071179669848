import { useEffect } from 'react';
import { headerSections } from '../data/sectionsData';
import { useSectionContext } from './SectionProvider';
import debounce from 'lodash.debounce';

 const ScrollListener = () => {
  const {  updateSectionId } = useSectionContext();

  const handleSectionChange = debounce((id: string) => {
    updateSectionId(id);
  }, 300);

  useEffect(() => {
    const options = {
      root: null, 
      rootMargin: '0px',
      threshold: 0.5, 
    };

    const observer = new IntersectionObserver((entries) => {
        let activeSectionId = null;

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            activeSectionId = entry.target.id; 
          }
        });
      
        if (activeSectionId) {
          handleSectionChange(activeSectionId); 
        }
    }, options);


    headerSections.forEach(({id}) => {
      const section = document.getElementById(id);
      if (section) {
        observer.observe(section);
      }
    });

   
    return () => {
        headerSections.forEach(({id}) => {
        const section = document.getElementById(id);
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSectionId]);
  
    return null; 
};


export default ScrollListener;