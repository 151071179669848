import { useTranslation } from "react-i18next";

interface RoleAndLocationProps {
    location: string;
    role: string;
    programmingLanguages: string[],
    workingModality?: string;
}

const RoleAndLocation = ({ workingModality, role, programmingLanguages, location }: RoleAndLocationProps) => {

    const {t} = useTranslation();

    return <section className="text-gray-600 dark:text-gray-400 text-base md:text-lg ">

        <div className="flex mb-1 items-baseline">
            <span className="mr-2 w-5 h-5 flex items-center justify-center text-light-pastel-blue">
                <i className="fa-solid fa-location-dot"></i>
            </span>
            {t(location)}
            {workingModality && <span className="italic">{t(workingModality)}</span>}
        </div>

        <div className="flex items-baseline ">
            <span className="mr-2 w-5 h-5 flex items-center justify-center text-light-pastel-blue">
                <i className="fa-solid fa-laptop"></i>
            </span>
            {`${role} with`}
            &nbsp;
            {
                programmingLanguages.map((item, index) => (
                    <span className="font-semibold" key={`language-${index}`}>{item}</span>
                ))
            }
        </div>
    </section>
}

export default RoleAndLocation;