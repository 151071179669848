export const personalProjectsData = [
    {
        id: 'vintage-cards',
        projectName: 'Vintage Cards',
        period: 'personal_projects.vintage_cards.period',
        description: 'personal_projects.vintage_cards.description',
        image: 'imgs/projects/vintage_cards.png',
        imageDecoration: 'imgs/projects/vintage_cards_catalog.png'
    },

    {
        id: 'bpms',
        projectName: 'personal_projects.bpms.name',
        period: 'personal_projects.bpms.period',
        description: 'personal_projects.bpms.description',
        image: 'imgs/projects/bpms.png',
        imageDecoration: 'imgs/projects/bpms.png'
    },
    {
        id: 'my-portfolio',
        projectName: 'personal_projects.my_portfolio.name',
        period: 'personal_projects.my_portfolio.period',
        description: 'personal_projects.my_portfolio.description',
        image: 'imgs/projects/my-portfolio.png',
        imageDecoration: 'imgs/projects/my-portfolio.png',
    },

];


export const vintageCardData = {
    title: 'Vintage Cards',

    features: [
        {
            name: 'personal_projects.vintage_cards.feat_ui',
            description: 'personal_projects.vintage_cards.feat_ui_description',
            image: '',
            imageName: ''
        },
        {
            name: 'personal_projects.vintage_cards.feat_cms',
            description: 'personal_projects.vintage_cards.feat_cms_description',
            image: 'imgs/projects/vintage_cards_cmd.png',
            imageName: ''
        },
        {
            name: 'personal_projects.vintage_cards.feat_catalog',
            description: 'personal_projects.vintage_cards.feat_catalog_description',
            image: 'imgs/projects/vintage_cards_catalog2.png',
            imageName: 'personal_projects.vintage_cards.image_catalog'
        },
        {
            name: 'personal_projects.vintage_cards.feat_dashboard',
            description: 'personal_projects.vintage_cards.feat_dashboard_descriptions',
            image: 'imgs/projects/vintage_cards_dashborad.png',
            imageName: ''
        },
        {
            name: 'personal_projects.vintage_cards.feat_journey',
            description: 'personal_projects.vintage_cards.feat_journey_description',
            image: 'imgs/projects/vintage_cards_journey.png',
            imageName: 'personal_projects.vintage_cards.image_journey'
        }
    ],

    technologies: [
        'personal_projects.vintage_cards.technology_1',
        'personal_projects.vintage_cards.technology_2',
        'personal_projects.vintage_cards.technology_3',
        'personal_projects.vintage_cards.technology_4',
        'personal_projects.vintage_cards.technology_5',
    ],

    colorsPalette: [
        {
            hexa: '#3F3F46',
            bg: 'bg-[#3F3F46]'
        },
        {
            hexa: '#155E75',
            bg: 'bg-[#155E75]'
        },
        {
            hexa: '#F0F9FF',
            bg: 'bg-[#F0F9FF]'
        },
        {
            hexa: '#F4DFC4',
            bg: 'bg-[#F4DFC4]'
        },
        {
            hexa: '#F2E6D7',
            bg: 'bg-[#F2E6D7]'
        },
        {
            hexa: '#F2ECE6',
            bg: 'bg-[#F2ECE6]'
        }
    ]
}

export const myPortfolioData = {

    technologies: [
        'personal_projects.vintage_cards.technology_1',
        'personal_projects.vintage_cards.technology_2',
        'personal_projects.vintage_cards.technology_3',
        'personal_projects.vintage_cards.technology_4',
    ],

}

export const bpmsData = {
    technologies: [
        'personal_projects.bpms.technology_1',
        'personal_projects.bpms.technology_2',
        'personal_projects.bpms.technology_3',
        'personal_projects.bpms.technology_4'
    ],
}