
import { useLocation, useNavigate } from "react-router-dom";
import { headerSections } from "../../data/sectionsData";
import { useSectionContext } from "../SectionProvider";
import DarkModeToggle from "./DarkModeToggle";
import LanguageToggle from "./LanguageToggle";
import HamburgerMenu from "./HamburgerMenu";
import { SectionId } from "../../enums/SectionId";
import { scrollToSection } from "../../functions/navigation-utils";
import useIsMobile from "../../hooks/useIsMobile";
import useIsTablet from "../../hooks/useIsTablet";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "../../hooks/useDarkMode";
import { useEffect, useRef } from "react";

const Header = () => {

  const isDarkMode = useDarkMode();

  const navigate = useNavigate();
  const location = useLocation();
  const isRoot = location.pathname === '/';

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const { t } = useTranslation();

  const { selectedSectionId, updateSectionId } = useSectionContext();

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleSectionClick = (sectionId: string) => {
    if (!isRoot) {
      navigate('/');

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        scrollToSection(sectionId);
      }, 100);
      return;
    }

    updateSectionId(sectionId);
    const section = document.getElementById(sectionId);
    if (section) {
      scrollToSection(sectionId)
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);


  return (
    <header className="fixed top-0 left-0 w-full shadow-lg z-50 flex items-center p-4 space-x-4 dark:shadow-inner-gray"
      style={{
        backgroundImage: isDarkMode ? `url('/imgs/bg/header-dark.png')` : `url('/imgs/bg/header.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className="flex justify-end items-center w-full ">
        <nav className="w-full flex items-center">

          <button
            onClick={() => handleSectionClick(SectionId.HOME)}
            className={`mr-auto text-md hover:text-blue-400 ${selectedSectionId === SectionId.HOME ? 'text-happy-blue' : 'text-gray-500 dark:text-gray-300'}`}
          >
            <span className="text-xl font-bold">
              {t(headerSections[0].name)}
            </span>

          </button>


          <ul className="space-x-4 hidden md:flex">
            {headerSections.map(({ id, name }, index) => (
              (index > 0) && <li key={`${id}-${selectedSectionId}`}>
                <button
                  onClick={() => handleSectionClick(id)}
                  className={`text-md hover:text-blue-400 ${selectedSectionId === id ? 'text-happy-blue' : 'text-gray-500 dark:text-gray-300'}`}
                >
                  {t(name).toUpperCase()}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        <div className="ml-4 flex items-center space-x-2 md:space-x-4">
          <DarkModeToggle />
          <LanguageToggle />
        </div>

      </div>


      {(isMobile || isTablet) &&
        <div className="flex justify-end items-center ml-2">
          <HamburgerMenu />
        </div>}
    </header>
  );
};

export default Header;
