import {  H2 } from "../layout/Headings";
import { myPortfolioData } from "../../data/personalProjectsData";
import { Trans, useTranslation } from "react-i18next";
import { SectionId } from "../../enums/SectionId";
import GoBackPageStructure from "../layout/GoBackPageStructure";
import P from "../layout/P";


const PortfolioPage = () => {
    const {t} = useTranslation();
    
    return <GoBackPageStructure 
                title={t('personal_projects.my_portfolio.name')}
                goBackTo={SectionId.PERSONAL_PROJECTS}>

                    <section className="w-full mb-8 text-gray-600 dark:text-gray-300">
                        <P>{t('personal_projects.my_portfolio.about_the_project')}</P>
                    </section>

                    <section className="mb-10 text-gray-600 dark:text-gray-300">
                        <H2 className="text-2xl font-semibold mb-4"> {t('personal_projects.my_portfolio.problem_title')}</H2>
                        <P>
                            {t('personal_projects.my_portfolio.problem_description')}
                        </P>
                    </section>

                    <section className="mb-10 text-gray-600 dark:text-gray-300">
                        <H2 className="text-2xl font-semibold mb-4">
                            {t('personal_projects.my_portfolio.solution')}
                        </H2>
                        <P>
                            {t('personal_projects.my_portfolio.solution_description')}

                        </P>
                    </section>

                    <section className="mb-10 text-gray-600 dark:text-gray-300">
                        <H2 className="text-2xl font-semibold mb-4"> {t('personal_projects.my_portfolio.technologies_title')}</H2>
                        <ul className="list-disc list-inside text-base">
                            {
                                myPortfolioData.technologies.map((item, index) => (
                                    <li className="mb-1" key={index}>
                                        {<Trans i18nKey={item} components={{ bold: <b /> }}/>}
                                    </li>
                                ))}
                        </ul>
                    </section>


                    <section className="mb-10 text-gray-600 dark:text-gray-300">
                        <H2 className="text-2xl font-semibold mb-4">
                            {t('personal_projects.my_portfolio.design_title')}

                        </H2>
                        <P>
                            {t('personal_projects.my_portfolio.design_description')}
                        </P>
                    </section>

    </GoBackPageStructure>
}

export default PortfolioPage;