export const experienceExtendedData = [
    {
        image: 'imgs/fourveneus/fourvenues_logo.jpeg',
        company: 'Fourvenues',
        link: 'https://www.fourvenues.com/es',
        period: 'fourvenues.working_period',
        workingModality: 'fourvenues.working_modality',
        location: 'global.location.valencia',
        role: 'Frontend Developer',
        programmingLanguages: ['Angular 15'],
        companyDescription: 'fourvenues.company_description',
        experienceTasks: [
            'fourvenues.experience_tasks.line_2',
            'fourvenues.experience_tasks.line_3',
            'fourvenues.experience_tasks.line_4',
            'fourvenues.experience_tasks.line_5',
            'fourvenues.experience_tasks.line_6',
            'fourvenues.experience_tasks.line_7',
            'fourvenues.experience_tasks.line_8',
            'fourvenues.experience_tasks.line_9',
            'fourvenues.experience_tasks.line_10',
            'fourvenues.experience_tasks.line_11',
            'fourvenues.experience_tasks.line_12',
            'fourvenues.experience_tasks.line_13',
            'fourvenues.experience_tasks.line_14',
            'fourvenues.experience_tasks.line_15',
        ],
        managementTool: 'Notion',
        respositoryHosting: 'Bitbuket, GitLab',
        versionControlSystem: 'GIT',
        toolImg: 'imgs/illustrations/board.svg',
        ui: 'Figma',
        projects: [
            {
                title: 'POS',
                description: 'fourvenues.projects.pos_description',
                image: 'imgs/fourveneus/fourveneus_pos.jpeg'
            }
        ],
        technicalSkills: ['Angular', 'Tailwind', 'HTML', 'Typescript', 'Javascript', 'Socket.io', 'RESTful endpoints', 'Jasmine & Karma', 'Phrase', 'Notion', 'Bitbucket', 'gitLab', 'GIT']

    },
    {
        image: 'imgs/workday/workday_logo.png',
        company: 'Workday',
        link: 'https://www.workday.com/',
        period: 'workday.working_period',
        workingModality: 'workday.working_modality',
        location: 'global.location.dublin',
        role: 'Software Engineer II',
        programmingLanguages: ['React'],
        companyDescription: 'workday.company_description',
        experienceTasks: [
            'workday.experience_tasks.line_2',
            'workday.experience_tasks.line_3',
            'workday.experience_tasks.line_4',
            'workday.experience_tasks.line_5',
            'workday.experience_tasks.line_6',
            'workday.experience_tasks.line_7',
            'workday.experience_tasks.line_8',
            'workday.experience_tasks.line_9',
            'workday.experience_tasks.line_10',
            'workday.experience_tasks.line_11',
            'workday.experience_tasks.line_12',        
            'workday.experience_tasks.line_13',

        ],
        managementTool: 'Jira & Confluence',
        respositoryHosting: 'GitHUb',
        versionControlSystem: 'GIT',
        toolImg: '/imgs/illustrations/hand-coding.svg',
        ui: 'Figma',
        projects: [
            {
                title: 'Learning',
                description: 'workday.projects.learning_description',
                image: 'imgs/workday/workday_learning.png'
            },
            {
                title: 'Help',
                description: 'workday.projects.help_description',
                image: 'imgs/workday/workday_help.png'
            }
        ],
        technicalSkills: ['React', 'Javascript', 'HTML', 'CSS', 'Storybook', 'Jest', 'GraphQL', 'Apollo', 'Emotion', 'Jira', 'Confluence', 'GIT']
    },
    {
        image: 'imgs/switch/switch_logo.png',
        company: 'Switch Software Solutions',
        link: 'https://www.switchsoftware.io/',
        period: 'switch.working_period',
        location: 'global.location.montevideo',
        role: 'Software Developer',
        programmingLanguages: ['Angular', 'NodeJs'],
        companyDescription: 'switch.company_description',
        experienceTasks: [
            'switch.experience_tasks.line_2',
            'switch.experience_tasks.line_3',
            'switch.experience_tasks.line_4',
            'switch.experience_tasks.line_5',
            'switch.experience_tasks.line_6',
            'switch.experience_tasks.line_7',
            'switch.experience_tasks.line_8',
            'switch.experience_tasks.line_9',
            'switch.experience_tasks.line_10',
            'switch.experience_tasks.line_11',
            'switch.experience_tasks.line_12'

        ],
        managementTool: 'Jira',
        respositoryHosting: 'Bitbucket',
        versionControlSystem: 'GIT',
        toolImg: 'Accept tasks-rafiki.svg',
        projects: [
            {
                title: 'TATA',
                description: 'switch.projects.tata_description',
                image: 'imgs/switch/switch_tata.png'
            },
            {
                title: 'SODEXO',
                description: 'switch.projects.sodexo_description',
                image: 'imgs/switch/switch_sodexo.png'
            }
        ],
        technicalSkills: ['Angular', 'NodeJS', 'HTML', 'Typescript', 'Javascript', 'Bootstrap', 'RESTful APIs', 'PostgreSQL', 'Scrum', 'Jira', 'Source Tree', 'GIT', 'Bitcket']
    },
    {
        image: 'imgs/atos/atos_logo.jpg',
        company: 'Atos',
        link: 'https://atos.net/en/',
        period: 'atos.working_period',
        location: 'global.location.montevideo',
        role: 'Software Developer',
        programmingLanguages: ['Genexus', 'PHP'],
        companyDescription: 'atos.company_description',
        experienceTasks: [
            'atos.experience_tasks.line_1',
            'atos.experience_tasks.line_2',
            'atos.experience_tasks.line_3',
            'atos.experience_tasks.line_4',
            'atos.experience_tasks.line_5',
            'atos.experience_tasks.line_6'
        ],
        projects: [
            {
                title: 'REDPAGOS',
                description: 'atos.projects.redpagos_description',
                image: 'imgs/atos/atos_redpagos.png'
            },
            {
                title: 'MSP',
                description: 'atos.projects.msp_description',
                image: 'imgs/atos/atos_msp.png'
            },
            {
                title: 'BUSES',
                description: 'atos.projects.buses_description',
                image: 'imgs/atos/atos_buses.png'
            }
        ],
        technicalSkills: ['Genexus Evoluion 3', 'PHP', 'AJAX', 'HTML', 'CSS', 'Javascript']
    },

    {
        image: 'imgs/genexus/genexus_consulting_logo.png',
        company: 'Genexus Consulting',
        link: 'https://www.genexusconsulting.com/',
        period: 'April 2012- November 2016',
        location: 'global.location.montevideo',
        role: 'Genexus Senior Developer',
        programmingLanguages: ['Genexus'],
        companyDescription: 'genexus.company_description',
        experienceTasks: [
            'genexus.experience_tasks.line_1',
            'genexus.experience_tasks.line_2',
            'genexus.experience_tasks.line_3',
            'genexus.experience_tasks.line_4',
            'genexus.experience_tasks.line_5',
            'genexus.experience_tasks.line_6',
            'genexus.experience_tasks.line_7',
            'genexus.experience_tasks.line_8',

        ],
        projects: [
            {
                title: 'SNIG',
                description: 'genexus.projects.mgap_description',
                image: 'imgs/genexus/genexus_snig.png'
            }
        ],
        technicalSkills: []
    }
];