import { headerSections } from "../data/sectionsData";
import { ExperienceDataIndex } from "../enums/ExperienceDataIndex.enum";
import { HEADER_HEIGHT } from "../enums/RoutePath.enum";
import { SectionId } from "../enums/SectionId";

export const  getNavigationPath = (value: number): string | undefined => {
    const path = ExperienceDataIndex[value];
    return path !== undefined ? path.toLowerCase() : undefined;
}


export const scrollToSection = (sectionId: string) => {
  
        const section = document.getElementById(sectionId);
        if (section) {
  
          const elementPosition = section.getBoundingClientRect().top + window.scrollY;
          const offsetPosition = elementPosition - HEADER_HEIGHT;
  
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
     

}


export const getSectionNameById = (id: SectionId) => {
  const section = headerSections.find(section => section.id === id);
  return section?.name ?? '';
}