import { useTranslation } from "react-i18next";
import SectionTitle from "./layout/SectionTitle";
import TextIconLink from "./layout/TextIconLink";
import { LanguageCodes } from "../enums/Languages";
import { SectionId } from "../enums/SectionId";
import { getSectionNameById } from "../functions/navigation-utils";

const ContactSection = () => {

    const { i18n, t } = useTranslation();

    const currentLanguage = i18n.language;
    
    return <>
        <SectionTitle title={getSectionNameById(SectionId.CONTACT)} />
        <div className="flex items-center justify-center mb-4">
            <img className="w-1/2 md:w-1/4 opacity-90 dark:brightness-75" src="/imgs/illustrations/email.svg" alt="Email"/>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-8 items-center justify-center ">

            <div className="flex items-center justify-center">
                <div className="w-full bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 shadow-md rounded-lg p-8">
                    <span className="flex justify-center text-6xl text-light-pastel-blue"><i className="fa-brands fa-linkedin"></i></span>
                    <div className="flex justify-center mt-4">
                        <TextIconLink link="https://www.linkedin.com/in/karenbas/" name="karenbas" />
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-center">
                <div className="w-full bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 shadow-md rounded-lg p-8">
         
                    <div className="flex justify-center"> 
                        <span className="flex justify-center items-center text-6xl bg-light-pastel-blue w-14 h-14 p-4 rounded-md">
                            <i className="text-white dark:text-gray-700 text-4xl fa-solid fa-file"></i>
                        </span>
                    </div>

                    <div className="flex justify-center mt-4">
                        <a className="flex items-baseline hover:text-happy-blue text-gray-600 dark:text-gray-300 text-sm font-semibold" href={currentLanguage === LanguageCodes.EN ? "/pdf/Karen_Bas_CV_english.pdf" : "/pdf/Karen_Bas_CV_spanish.pdf"} download="Karen Bas - CV.pdf">
                            {t('contact.download_cv')}
                            <span className="flex text-sm justify-center text-happy-blue ml-2">
                                <i className="fa-solid fa-download"></i>
                            </span>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ContactSection;