import { ReactNode } from "react";

interface PProps {
    children: ReactNode;
}

const P = ({children}: PProps) => {
    return  <p className="text-gray-600 dark:text-gray-300 text-justify text-base md:text-lg ">
    {children}
</p>
}

export default P;
