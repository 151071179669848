import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageCodes } from "../../enums/Languages";

const LanguageToggle = () => {

    const { i18n } = useTranslation();
    const [isEnglish, setIsEnglish] = useState(true);

    const toggleFlag = () => {
        const newLang = isEnglish ? LanguageCodes.ES : LanguageCodes.EN;
        i18n.changeLanguage(newLang); 
        setIsEnglish(!isEnglish);
    };

    return <button
        className="flex items-center py-1 px-4 w-14 rounded-full bg-gray-200 hover:bg-gray-300  dark:bg-gray-800 dark:hover:bg-gray-700 flex items-center justify-center transition-all duration-300 ml-4"
        onClick={toggleFlag}
    >
        {isEnglish && <span className="text-xs mr-2 text-gray-600 dark:text-gray-300">EN</span>}
        <img
            src={isEnglish ? "imgs/languages/uk.svg" : "/imgs/languages/es.svg"}
            alt={isEnglish ? "UK Flag" : "Spanish Flag"}
            className="w-3.5 h-3.5 rounded-full object-cover opacity-70"
        />
         {!isEnglish && <span className="text-xs ml-2 text-gray-600 dark:text-gray-300">ES</span>}
    </button>

};

export default LanguageToggle;