export enum ButtonType {
  PRIMARY = "primary",
  PRIMARY_ROUNDED = "primary-rounded",
}

export enum ButtonSize {
  XS = "xs",
  NORMAL = "normal",
}

interface ButtonProp {
  type: ButtonType;
  icon?: string;
  handleClick?: () => void;
  children?: React.ReactNode;
  isDisabled?: boolean;
  size?: ButtonSize;
}


const Button = ({
  type,
  size = ButtonSize.NORMAL,
  icon,
  handleClick,
  isDisabled = false,
  children,
}: ButtonProp) => {

  const getButtonClass = () => {

    let classes = '';

    if (isDisabled) {
      classes += "text-white bg-gray-300  py-1 px-2"
    }

    if (type === ButtonType.PRIMARY)
      classes += ' text-happy-blue hover:text-blue-400 w-6';

    if (type === ButtonType.PRIMARY_ROUNDED)
      classes += ' text-gray-50 bg-happy-blue rounded-lg shadow-md hover:bg-blue-400 inline-block font-semibold text-sm md:text-base px-4 py-2 ';

    if (size === ButtonSize.XS) {
      classes += "text-sm md:text-base";
    } else {
      classes += "text-sm md:text-base";
     
    }

    return classes;

  }

  return (
    <button
      className={`${getButtonClass()}`}
      onClick={handleClick}
      disabled={isDisabled}
      type="submit"
    >
      <div className="border-1 border-zinc-900 ">
        {icon && (
          <span className="mr-2" >
            <i className={icon}></i>
          </span>
        )}
        {children}

      </div>
    </button>
  );
};

export default Button;
