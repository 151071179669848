interface TextIconLinkProps {
    link: string;
    name?: string;
}

const TextIconLink = ({ link, name }: TextIconLinkProps) => {

    return <a className="flex items-center hover:text-blue-400 text-gray-600 dark:text-gray-300" href={link} target="_blank" rel="noopener noreferrer">
        {name && <span className="font-semibold">
            {name}
        </span>}
        <span className="flex items-center text-xs hover:text-blue-400 text-happy-blue ml-2">
            <i className="fa-solid fa-arrow-up-right-from-square"></i>
        </span>
    </a>
}

export default TextIconLink