import { useTranslation } from "react-i18next";
import TextIconLink from "./TextIconLink";

interface CertificaionProps {
    name: string;
    issuingEntity: string;
    period: string;
    link?: string;
    location?: string;
}

const Certification = ({ name, issuingEntity, period, link, location }: CertificaionProps) => {

    const { t } = useTranslation();


    return <div className="flex mb-1 text-base md:text-lg  items-baseline p-4 border border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-600 dark:text-gray-300 rounded-lg">
        <div className="pr-4 flex h-full items-start justify-center text-light-pastel-blue text-sm">
            <i className="fa-solid fa-certificate"></i>
        </div>
        <div>
            {!link && <div className="font-semibold text-gray-600 dark:text-gray-300">{t(name)}</div>}
            
            {link && <div className="text-gray-600 dark:text-gray-300">
                <TextIconLink link={link} name={t(name)}/>
            </div>}
            <div >
                {t(issuingEntity)}
                {location && <span className="ml-2">| {t(location)}</span>}
            </div>
            <div className="italic"> {t(period)}</div>
        </div>
    </div>
}

export default Certification;