import React, { useRef } from 'react';
import HomeSection from '../HomeSection';
import ExperienceTimeLine from '../ExperienceTimeLine';
import EducationSection from '../EducationSection';
import ContactSection from '../ContactSection';
import { headerSections } from '../../data/sectionsData';
import { SectionId } from '../../enums/SectionId';
import PersonalProjectsSection from '../PersonalProjectsSection';
import ScrollListener from '../ScrollListener';
import SectionWrapper from '../layout/SectionWrapper';


const getSection = (sectionId: string) => {
  const sections: Record<string, JSX.Element> = {

    [SectionId.HOME]: <HomeSection />,

    [SectionId.PROFESSIONAL_EXPERIENCE]: (
      <SectionWrapper>
        <ExperienceTimeLine />
      </SectionWrapper>
    ),
    
    [SectionId.EDUCATION]: (
      <div className="w-full bg-light-yellow dark:bg-gray-800  min-h-screen">
        <SectionWrapper>
          <EducationSection />
        </SectionWrapper>
      </div>
    ),
    [SectionId.PERSONAL_PROJECTS]: (
      <div className="bg-gray-100 dark:bg-gray-900">
        <SectionWrapper>
          <PersonalProjectsSection />
        </SectionWrapper>
      </div>
    ),
    [SectionId.CONTACT]: (
      <div className="min-h-screen w-full bg-light-yellow dark:bg-gray-800">
        <SectionWrapper>
          <ContactSection />
        </SectionWrapper>
      </div>
    ),
  };


  return sections[sectionId] || null;
};

const ResumePage = () => {

  const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  return (
    <>
      <ScrollListener />
      {headerSections.map(({id}) => (
        <section id={id} 
          key={id}
          ref={(el: HTMLDivElement | null) => {
            sectionRefs.current![id] = el;
          }}
        >
          {getSection(id)}
        </section>
      ))}
    </>

  );
};

export default ResumePage;
