import { Trans } from "react-i18next";

interface ExperienceCardProps {
    title?: string;
    experienceTasks: string[]
}

const ExperienceSection = ({ title, experienceTasks }: ExperienceCardProps) => {

    return <>
        {title && <div className="mb-4">{title}</div>}
        <ul className="list-none pl-0 text-base md:text-lg ">
            {experienceTasks.map((item, index) => (
                <li key={index} className="relative pl-4 mb-2 text-gray-600 dark:text-gray-300">
                    <span
                        className="absolute left-0 top-3 transform -translate-y-1/2 w-2 h-2 rounded-full bg-light-pastel-blue"
                    />
                    {<Trans i18nKey={item} components={{ bold: <b /> }}/>}
                </li>
            ))}
        </ul>
    </>

}

export default ExperienceSection;